export const Loading = () => {
  return (
    <div
      className="h-screen w-screen flex items-center justify-center
                bg-color-surface-secondary"
    >
      Loading...
    </div>
  );
};
