import { useRef, useState } from 'react';

/* components */
import { Trigger } from '../Common/Trigger/Trigger';
import { OrganisationMenu } from './OrganisationMenu/OrganisationMenu';

/* redux */
import { useAppSelector } from '../../../redux/hooks';

/* hooks */
import useOutsideClick from '../../../hooks/useOutsideClick';

/* images */
import Placeholder from '../../../images/placeholders/purple.svg';

export const Organisation = () => {
  const organisation = useAppSelector((state) => state.organisation);

  const [organisationMenuDisplayed, setOrganisationMenuDisplayed] =
    useState<boolean>(false);

  /* close the menu if clicked outside of it */
  const organisationMenu = useRef(null);
  useOutsideClick(organisationMenu, () => setOrganisationMenuDisplayed(false));

  return (
    <div ref={organisationMenu} className="py-sm">
      {/* menu */}
      {organisationMenuDisplayed && (
        <OrganisationMenu handleDisplay={setOrganisationMenuDisplayed} />
      )}
      {/* trigger */}
      <Trigger
        active={organisationMenuDisplayed}
        image={
          <img
            src={Placeholder}
            alt={organisation.name}
            className="w-full h-full"
          />
        }
        label={<h6>{organisation.name}</h6>}
        onClick={() => setOrganisationMenuDisplayed(!organisationMenuDisplayed)}
      />
    </div>
  );
};
