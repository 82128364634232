import { ChangeEvent } from 'react';
import classNames from 'classnames';

/* components */
import { Input } from '../Input';

/* icons */
import { ReactComponent as SearchIcon } from '../../../../icons/search.svg';

interface Props {
  placeholder?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

export const Search = (props: Props) => {
  const { placeholder, disabled } = props;

  return (
    <Input
      name="Search"
      icon={
        <SearchIcon
          className={classNames(
            'w-4 h-4 fill-color-icon-primary',
            disabled && 'opacity-30'
          )}
        />
      }
      clear={true}
      placeholder={placeholder || 'Search'}
      disabled={disabled}
      onChange={props.onChange}
      onClear={props.onClear}
    />
  );
};
