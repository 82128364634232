import { configureStore } from '@reduxjs/toolkit';

/* slices */
import organisationReducer from './slices/organisation';
import loadingReducer from './slices/loading';
import userReducer from './slices/user';

/* store */
export const store = configureStore({
  reducer: {
    organisation: organisationReducer,
    loading: loadingReducer,
    user: userReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
