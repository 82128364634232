import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult
} from '@azure/msal-browser';

/* service worker */
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/* components */
import App from './App';

/* configs */
import { msalConfig } from './configs/b2c';

/* redux */
import { store } from './redux/store';

/* styles */
import './index.scss';

/* msal instance */
export const msalInstance = new PublicClientApplication(msalConfig);

/* default to first account if no active account */
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

/* set active account on login success */
msalInstance.addEventCallback((event: EventMessage) => {
  const payload = event.payload as AuthenticationResult;

  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    payload?.account
  ) {
    msalInstance.setActiveAccount(payload.account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
