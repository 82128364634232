import { Outlet } from 'react-router-dom';

/* components */
import { Navigation } from '../Navigation/Navigation';
import { Loading } from './Loading/Loading';

/* redux */
import { useAppSelector } from '../../redux/hooks';

export const Layout = () => {
  const loading = useAppSelector((state) => state.loading);

  return loading ? (
    <Loading />
  ) : (
    <div className="h-screen overflow-hidden p-4">
      <div className="flex gap-4">
        {/* navigation */}
        <Navigation />
        {/* content */}
        <div
          className="w-full h-[calc(100vh-32px)] rounded-large bg-color-surface-body
                    border border-color-stroke-tertiary p-4 overflow-y-auto"
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
