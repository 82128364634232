import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';

/* components */
import { NavItem } from '../NavItem/NavItem';

interface Props {
  to: string;
  icon: ReactNode;
  label: string;
  notifications: number;
  disabled?: boolean;
  className?: string;
  activeClassName?: string;
}

export const Link = (props: Props) => {
  const { to, icon, label, notifications, disabled } = props;

  const [active, setActive] = useState(false);

  return (
    <NavLink
      to={to}
      tabIndex={disabled ? -1 : 0}
      className={({ isActive }) => {
        setActive(isActive);

        if (disabled) return 'pointer-events-none';

        return `overflow-hidden rounded-standard focus-visible:outline 
                outline-[3px] outline-offset-0 outline-color-stroke-brand-focus`;
      }}
    >
      <NavItem
        icon={icon}
        label={label}
        notifications={notifications}
        active={active}
        disabled={disabled}
        className={props.className}
        activeClassName={props.activeClassName}
      />
    </NavLink>
  );
};
