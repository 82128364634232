import { ReactNode } from 'react';
import classNames from 'classnames';

/* components */
import { NavItem } from '../NavItem/NavItem';

interface Props {
  icon: ReactNode;
  label: string;
  notifications: number;
  active?: boolean;
  activeIcon?: ReactNode;
  disabled?: boolean;
  className?: string;
  activeClassName?: string;
  onClick: () => void;
}

export const Button = (props: Props) => {
  const { icon, label, notifications, active, activeIcon, disabled } = props;

  return (
    <button
      onClick={() => props.onClick()}
      tabIndex={disabled ? -1 : 0}
      className={classNames(
        'overflow-hidden rounded-standard w-full',
        /* disabled */
        disabled ? 'cursor-default' : props.className
      )}
    >
      <NavItem
        icon={icon}
        label={label}
        notifications={notifications}
        disabled={disabled}
        active={active}
        activeIcon={activeIcon}
        className={props.className}
        activeClassName={props.activeClassName}
      />
    </button>
  );
};
