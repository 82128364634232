const tenant = 'narrowapp';
const clientId = 'dfbf2a4f-685c-416e-8207-339ca9a5029f';

/* scopes */
export const loginRequest = {
  scopes: ['openid', 'offline_access', 'profile']
};

/* policies */
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    forgotPassword: 'B2C_1A_PASSWORDRESET',
    editProfile: 'B2C_1A_PROFILEEDIT'
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`
    },
    forgotPassword: {
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/B2C_1A_PASSWORDRESET`
    },
    editProfile: {
      authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/B2C_1A_PROFILEEDIT`
    }
  },
  authorityDomain: `${tenant}.b2clogin.com`
};

/* config */
export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};
