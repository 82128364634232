import { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  icon: ReactNode;
  label: string;
  notifications: number;
  active?: boolean;
  activeIcon?: ReactNode;
  disabled?: boolean;
  className?: string;
  activeClassName?: string;
}

export const NavItem = (props: Props) => {
  const { icon, label, notifications, active, activeIcon, disabled } = props;

  return (
    <div
      className={classNames(
        'flex items-center justify-between px-sm py-xs h-[36px]',
        'rounded-standard overflow-hidden fill-color-icon-tertiary',
        'text-color-text-tertiary',
        /* active */
        active
          ? props.activeClassName || 'bg-color-navigation-panel-surface-primary'
          : /* disabled */
          disabled
          ? 'opacity-30'
          : /* hover */
            classNames(
              'hover:text-color-text-secondary hover:fill-color-icon-secondary',
              props.className ||
                'hover:bg-color-navigation-panel-surface-secondary'
            )
      )}
    >
      <div className="flex gap-xs items-center">
        {/* icon */}
        <div
          className={classNames(
            'w-4 h-4',
            /* active */
            active ? 'fill-color-icon-primary' : 'fill-inherit'
          )}
        >
          {icon}
        </div>
        {/* label */}
        <p
          className={classNames(
            'medium',
            /* active */
            active ? 'text-color-text-primary' : 'text-inherit'
          )}
        >
          {label}
        </p>
      </div>
      <div>
        {/* notifications */}
        {notifications > 0 && (
          <div
            className="h-5 w-5 flex items-center justify-center rounded-small
                      border border-color-divider-primary bg-color-surface-quinary"
          >
            <p className="subheading-condensed text-color-text-secondary">
              {notifications}
            </p>
          </div>
        )}
        {/* active icon */}
        {!notifications && active && activeIcon && (
          <div className="">{activeIcon}</div>
        )}
      </div>
    </div>
  );
};
