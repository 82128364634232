import { ReactNode } from 'react';
import classNames from 'classnames';

/* icons */
import { ReactComponent as ChevronUpDown } from '../../../../icons/chevron-up-down.svg';

interface Props {
  active: boolean;
  image: ReactNode;
  label: ReactNode;
  onClick: () => void;
}

export const Trigger = (props: Props) => {
  const { active, image, label } = props;

  return (
    <button
      onClick={() => props.onClick()}
      className={classNames(
        'flex w-full items-center justify-between pr-sm pl-xs py-xs rounded-standard',
        /* active */
        active
          ? 'bg-color-navigation-panel-surface-primary'
          : /* hover */
            `hover:bg-color-navigation-panel-surface-secondary`
      )}
    >
      <div className="flex gap-sm items-center">
        {/* image */}
        <div className="h-6 w-6 rounded-small">{image}</div>
        {/* label */}
        {label}
      </div>
      {/* menu button */}
      <div className="h-4 w-4 flex items-center">
        <ChevronUpDown
          className={classNames(
            active ? 'fill-color-icon-secondary' : 'fill-color-icon-tertiary'
          )}
        />
      </div>
    </button>
  );
};
