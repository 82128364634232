import { ChangeEvent, ReactNode, useState } from 'react';
import classNames from 'classnames';

/* icons */
import { ReactComponent as Clear } from '../../../icons/clear.svg';
import { ReactComponent as Visible } from '../../../icons/visible.svg';
import { ReactComponent as Invisibile } from '../../../icons/invisible.svg';

interface Props {
  name: string;
  type?: 'text' | 'password' | 'tel';
  icon?: ReactNode;
  select?: ReactNode;
  clear?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  variant?: 'inline' | 'stacked';
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

export const Input = (props: Props) => {
  const {
    name,
    type,
    select,
    icon,
    clear,
    label,
    placeholder,
    disabled,
    required,
    variant
  } = props;

  const [value, setValue] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(type !== 'password');

  const widths = {
    icon: 16,
    label: 64,
    select: 60
  };

  const width =
    (icon ? widths['icon'] : 0) +
    (select ? widths['select'] + 4 : 0) +
    (label ? widths['label'] + 8 : 0);

  const padding = width > 0 ? width + 18 : 12;

  return (
    <div className="relative flex items-center">
      <div
        className="absolute flex ml-sm h-full items-center gap-2"
        style={{
          width: variant === 'inline' ? `${width}px` : 0
        }}
      >
        {/* icon */}
        {icon && variant === 'inline' && (
          <div className="flex items-center justify-center w-4 h-4">{icon}</div>
        )}

        {/* label */}
        {label && variant === 'inline' && (
          <label
            htmlFor={name}
            className={classNames(
              'overflow-hidden w-16',
              disabled && 'opacity-30'
            )}
          >
            {label}
          </label>
        )}

        {/* select (inline) */}
        {select && variant === 'inline' && (
          <div
            className={classNames(
              'flex items-center border-r border-color-stroke-secondary w-16 -ml-2xs',
              label && 'ml-0'
            )}
          >
            {select}
          </div>
        )}
      </div>

      {/* select (stacked) */}
      {select && variant === 'stacked' && (
        <div className="w-full absolute top-1 px-xs">
          <div className="border-b border-color-stroke-secondary py-2xs flex">
            {select}
          </div>
        </div>
      )}

      {/* input */}
      <input
        className={classNames(
          clear && variant === 'inline' && 'pr-10',
          variant === 'stacked' ? 'h-16 pt-8' : 'h-8',
          'flex-1',
          props.className
        )}
        style={{
          paddingLeft: variant === 'inline' ? `${padding}px` : '12px'
        }}
        name={name}
        value={value}
        type={visible ? 'text' : type}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange(e);
        }}
      />

      {/* clear */}
      {clear && type !== 'password' && (
        <button
          className="absolute w-4 h-4 right-sm flex items-center"
          onClick={(e) => {
            e.preventDefault();
            setValue('');

            if (props.onClear) props.onClear();
          }}
          disabled={disabled}
        >
          <Clear
            className={classNames(
              'fill-color-icon-primary',
              disabled && 'opacity-30'
            )}
          />
        </button>
      )}

      {/* visibility */}
      {type === 'password' && (
        <button
          className="absolute w-4 h-4 right-sm flex items-center"
          onClick={(e) => {
            e.preventDefault();
            setVisible(!visible);
          }}
          disabled={disabled}
        >
          {visible ? (
            <Invisibile
              className={classNames(
                'fill-color-icon-primary',
                disabled && 'opacity-30'
              )}
            />
          ) : (
            <Visible
              className={classNames(
                'fill-color-icon-primary',
                disabled && 'opacity-30'
              )}
            />
          )}
        </button>
      )}
    </div>
  );
};
