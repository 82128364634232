import axios from 'axios';

/* configs */
import { msalInstance } from '..';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

/* attach an access token to each request */
api.interceptors.request.use(async (config) => {
  const request = {
    scopes: ['https://narrow.app/dfbf2a4f-685c-416e-8207-339ca9a5029f/user'],
    account: msalInstance.getAllAccounts()[0]
  };

  const token = await msalInstance
    .acquireTokenSilent(request)
    .then((response) => response.accessToken)
    .catch(() =>
      msalInstance
        .acquireTokenPopup(request)
        .then((response) => response.accessToken)
    );

  const bearer = `Bearer ${token}`;

  config.headers.Authorization = bearer;

  return config;
});

export default api;
