/* icons */
import { ReactComponent as Search } from '../../icons/search.svg';
import { ReactComponent as Bell } from '../../icons/bell.svg';
import { ReactComponent as Checked } from '../../icons/checked.svg';
import { ReactComponent as Dashboard } from '../../icons/dashboard.svg';
import { ReactComponent as Calendar } from '../../icons/calendar.svg';
import { ReactComponent as Users } from '../../icons/users.svg';

/* components */
import { Organisation } from './Organisation/Organisation';
import { Button } from './Button/Button';
import { Link } from './Link/Link';
import { User } from './User/User';
import { Divide } from '../Common/Divide/Divide';

export const Navigation = () => {
  return (
    <nav
      className="w-[280px] h-[calc(100vh-32px)] flex-shrink-0
                flex flex-col bg-color-surface-base relative"
    >
      {/* organisation */}
      <Organisation />

      {/* divide */}
      <Divide />

      {/* actions */}
      <div className="flex flex-col gap-2xs py-md">
        <Button
          icon={<Search className="fill-inherit" />}
          label="Search"
          notifications={0}
          onClick={() => null}
        />
        <Button
          icon={<Bell className="fill-inherit" />}
          label="Notifications"
          notifications={1}
          onClick={() => null}
        />
        <Button
          icon={<Checked className="fill-inherit" />}
          label="Tasks"
          notifications={0}
          onClick={() => null}
          disabled
        />
      </div>

      {/* divide */}
      <Divide />

      {/* links */}
      <ul className="flex flex-col gap-2xs py-md flex-grow">
        <Link
          to="/"
          icon={<Dashboard className="fill-inherit" />}
          label="Dashboard"
          notifications={4}
        />
        <Link
          to="/inputs"
          icon={<Calendar className="fill-inherit" />}
          label="Inputs"
          notifications={0}
        />
        <Link
          to="/team"
          icon={<Users className="fill-inherit" />}
          label="Team"
          notifications={0}
        />
      </ul>

      {/* user */}
      <User />
    </nav>
  );
};
