import { useRef, useState } from 'react';

/* components */
import { Trigger } from '../Common/Trigger/Trigger';
import { UserMenu } from './UserMenu/UserMenu';

/* redux */
import { useAppSelector } from '../../../redux/hooks';

/* hooks */
import useOutsideClick from '../../../hooks/useOutsideClick';

/* images */
import { ReactComponent as Placeholder } from '../../../images/placeholders/purple.svg';

export const User = () => {
  const user = useAppSelector((state) => state.user);

  const [userMenuDisplayed, setUserMenuDisplayed] = useState<boolean>(false);

  /* close the menu if clicked outside of it */
  const userMenu = useRef(null);
  useOutsideClick(userMenu, () => setUserMenuDisplayed(false));

  return (
    <div ref={userMenu}>
      {/* menu */}
      {userMenuDisplayed && <UserMenu handleDisplay={setUserMenuDisplayed} />}
      {/* trigger */}
      <Trigger
        active={userMenuDisplayed}
        image={<Placeholder />}
        label={
          <p className="medium">{`${user.first_name} ${user.last_name}`}</p>
        }
        onClick={() => setUserMenuDisplayed(!userMenuDisplayed)}
      />
    </div>
  );
};
