import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/* components */
import { Link } from '../../Link/Link';
import { Button } from '../../Button/Button';
import { Divide } from '../../../Common/Divide/Divide';

/* redux */
import { setOrganisation } from '../../../../redux/slices/organisation';
import { useAppSelector } from '../../../../redux/hooks';

/* configs */
import api from '../../../../configs/api';

/* images */
import { ReactComponent as Name } from '../../../../images/name.svg';
import Placeholder from '../../../../images/placeholders/purple.svg';

/* icons */
import { ReactComponent as Add } from '../../../../icons/add.svg';
import { ReactComponent as Connectivity } from '../../../../icons/connectivity.svg';
import { ReactComponent as Settings } from '../../../../icons/settings.svg';
import { ReactComponent as Tick } from '../../../../icons/tick.svg';

/* types */
import { OrganisationI } from '../../../../types/organisation';

interface Props {
  handleDisplay: (display: boolean) => void;
}

export const OrganisationMenu = (props: Props) => {
  const dispatch = useDispatch();
  const selectedOrganisation = useAppSelector((state) => state.organisation);

  const [organisations, setOrganisations] = useState<OrganisationI[]>([]);

  /* get the users organisations */
  useEffect(() => {
    api
      .get(`/organisations`)
      .then((response) => setOrganisations(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div
      onClick={() => props.handleDisplay(false)}
      className="absolute w-full flex flex-col gap-2xs rounded-standard border 
                  border-color-stroke-secondary bg-color-menu-surface-base
                  top-[60px] z-10"
    >
      {/* organisations */}
      <div className="p-xs flex flex-col gap-2xs">
        <p className="caption text-color-text-tertiary px-xs py-2xs">
          Your Organisations
        </p>

        {/* organisations */}
        {organisations.map((organisation, index) => (
          <Button
            key={index}
            active={organisation.id === selectedOrganisation.id}
            onClick={() => dispatch(setOrganisation(organisation))}
            icon={<img src={Placeholder} alt={organisation.name} />}
            activeIcon={<Tick className="fill-color-icon-primary" />}
            label={organisation.name}
            notifications={0}
            className={'hover:bg-color-menu-surface-secondary'}
            activeClassName={'bg-color-menu-surface-primary'}
          />
        ))}

        {/* create organisation */}
        <Button
          onClick={() => null}
          icon={<Add className="fill-inherit" />}
          label={'Create Organisation'}
          notifications={0}
          className={'hover:bg-color-menu-surface-secondary'}
          activeClassName={'bg-color-menu-surface-primary'}
        />
      </div>

      <Divide />

      {/* links */}
      <div className="p-xs">
        <Link
          to="/organisation-preferences"
          icon={<Settings className="fill-inherit" />}
          label="Organisation Preferences"
          notifications={0}
          className={'hover:bg-color-menu-surface-secondary'}
        />
        <Link
          to="/integrations"
          icon={<Connectivity className="fill-inherit" />}
          label="Integrations"
          notifications={0}
          className={'hover:bg-color-menu-surface-secondary'}
        />
      </div>

      <Divide />

      {/* narrow */}
      <div className="flex justify-between items-center p-lg">
        <Name className="fill-color-text-primary" />
        <div className="flex gap-xs caption text-color-text-tertiary">
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/cookies">Cookies</a>
        </div>
      </div>
    </div>
  );
};
