import { useEffect, useState } from 'react';
import classNames from 'classnames';

interface Props {
  variant?: string;
  onChange: (value: string) => void;
}

export const CountryCodes = (props: Props) => {
  const { variant } = props;

  const codes = [
    {
      code: '+44',
      country: 'United Kingdom'
    },
    {
      code: '+22',
      country: 'Test Country'
    }
  ];

  const [value, setValue] = useState<string>(codes[0].code);

  useEffect(() => {
    props.onChange(value);
    // eslint-disable-next-line
  }, [value]);

  return (
    <select
      name="country_codes"
      className={classNames(
        'bg-transparent text-color-text-brand cursor-pointer outline-none pr-xs',
        'font-normal',
        variant === 'stacked' && 'flex-1'
      )}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange(e.target.value);
      }}
      value={value}
    >
      {codes.map((code) => (
        <option value={code.code}>
          {variant === 'stacked' ? `${code.code} (${code.country})` : code.code}
        </option>
      ))}
    </select>
  );
};
