import { useEffect, useState } from 'react';

/* redux */
import { useAppSelector } from '../../../../redux/hooks';

/* configs */
import api from '../../../../configs/api';

/* types */
import { UserI } from '../../../../types/user';

export const Team = () => {
  const organisation = useAppSelector((state) => state.organisation);

  const [members, setMembers] = useState<UserI[]>([]);

  useEffect(() => {
    if (organisation)
      api
        .get(`/organisation/${organisation.id}/members`)
        .then((response) => setMembers(response.data))
        .catch((error) => console.log(error));
  }, [organisation]);

  return (
    <div>
      <div>Team</div>
      <div>{JSON.stringify(members)}</div>
    </div>
  );
};
