import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* types */
import { RootState } from '../store';

const initialState = true;

/* slice */
export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (_state, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  }
});

/* actions */
export const { setLoading } = loadingSlice.actions;

/* state */
export const loading = (state: RootState) => state.loading;

/* reducer */
export default loadingSlice.reducer;
