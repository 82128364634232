import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* types */
import { RootState } from '../store';
import { UserI } from '../../types/user';

const initialState: UserI = {
  first_name: 'Unknown',
  last_name: 'User',
  email: ''
};

/* slice */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_state, action: PayloadAction<UserI>) => {
      return action.payload;
    }
  }
});

/* actions */
export const { setUser } = userSlice.actions;

/* state */
export const user = (state: RootState) => state.user;

/* reducer */
export default userSlice.reducer;
