import { useEffect, useState } from 'react';
import { AuthError, EventMessage, EventType } from '@azure/msal-browser';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react';

/* components */
import { Initialisation } from './components/Initialisation/Initialisation';
import { Layout } from './components/Layout/Layout';
import { Login } from './components/Layout/Content/Login/Login';
import { Dashboard } from './components/Layout/Content/Dashboard/Dashboard';
import { Inputs } from './components/Layout/Content/Inputs/Inputs';
import { Team } from './components/Layout/Content/Team/Team';
import { NotFound } from './components/Layout/Content/NotFound/NotFound';

/* configs */
import { b2cPolicies } from './configs/b2c';

const App = () => {
  const { instance } = useMsal();

  const [theme] = useState<'light' | 'dark'>('dark');

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      const error = event.error as AuthError;

      if (event.eventType === EventType.LOGIN_FAILURE) {
        /* forgot password */
        if (error && error.errorMessage.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: []
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <div
      data-theme={theme}
      className="h-screen w-screen center font-body font-medium
                bg-color-surface-base text-color-text-primary"
    >
      <AuthenticatedTemplate>
        <Initialisation />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="inputs" element={<Inputs />} />
              <Route path="team" element={<Team />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
