import { useMsal } from '@azure/msal-react';

/* images */
import logo from '../../../../images/logo.svg';

export const Login = () => {
  const { instance } = useMsal();

  return (
    <header className="h-full flex flex-col justify-center gap-16">
      <div className="flex items-center justify-center gap-4">
        <img src={logo} alt="logo" className="h-10" />
        <h1 className="text-5xl">Narrow</h1>
      </div>
      <button onClick={() => instance.loginRedirect()}>Login</button>
    </header>
  );
};
