import { ChangeEvent } from 'react';

/* components */
import { Input } from '../Input';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Text = (props: Props) => {
  const { name, label, placeholder, disabled, required } = props;

  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      onChange={props.onChange}
    />
  );
};
