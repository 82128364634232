const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    /* [::1] is the IPv6 localhost address */
    window.location.hostname === '[::1]' ||
    /* 127.0.0.0/8 are considered localhost for IPv4 */
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

/* registration entry */
export function register(config?: Config) {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    /* service worker needs matching origins to work - a CDN might break this */
    if (publicUrl.origin !== window.location.origin) {
      console.log(
        'Service worker could not be registered as origins do not match.'
      );
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        /* check for a valid service worker */
        checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready.then(() => {
          console.log('Service worker successfully registered.');
        });
      } else {
        /* not localhost so skip to registration */
        registerValidSW(swUrl, config);
      }
    });
  }
}

/* register a valid service worker */
function registerValidSW(swUrl: string, config?: Config) {
  console.log('Registering service worker.');

  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;

        /* return if update is not being installed */
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          /* check if update was installed */
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              /* display new content available message */
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed.'
              );

              /* callback */
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('Content is cached for offline use.');

              /* callback */
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

/* check for a valid service worker file */
function checkValidServiceWorker(swUrl: string, config?: Config) {
  console.log('Checking for a valid service worker.');

  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');

      /* register the service worker if it was found otherwise reload the app */
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        console.log('No service worker found.');

        navigator.serviceWorker.ready
          .then((registration) => {
            registration.unregister().then(() => {
              console.log('Reloading the app.');
              window.location.reload();
            });
          })
          .catch((error) =>
            console.log(
              'Error reloading the app while registering the service worker: ' +
                error.message
            )
          );
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

/* unregister the service worker */
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        console.log('Unregistering service worker registration.');
        registration.unregister();
      })
      .catch((error) => {
        console.error('Error unregistering service worker: ' + error.message);
      });
  }
}
