/* components */
import { ChangeEvent, useEffect, useState } from 'react';
import { Divide } from '../../../Common/Divide/Divide';
import { Password } from '../../../Common/Input/Password/Password';
import { Search } from '../../../Common/Input/Search/Search';
import { Telephone } from '../../../Common/Input/Telephone/Telephone';
import { Text } from '../../../Common/Input/Text/Text';
import { TextArea } from '../../../Common/Input/TextArea/TextArea';

export const Inputs = () => {
  const [fields, setFields] = useState<Record<string, string>>();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleClear = (name: string) => {
    setFields({ ...fields, [name]: '' });
  };

  useEffect(() => {
    console.log('fields', fields);
  }, [fields]);

  return (
    <form className="flex flex-col gap-4">
      <div>Text</div>
      <Text
        name="Text1"
        label="Label"
        placeholder="Placeholder"
        onChange={handleChange}
      />
      <Text name="Text2" placeholder="Placeholder" onChange={handleChange} />
      <Text
        name="Text3"
        label="Label"
        placeholder="Disabled"
        disabled
        onChange={handleChange}
      />
      <Text
        name="Text4"
        placeholder="Disabled"
        disabled
        onChange={handleChange}
      />

      <Divide />

      <div>Text Area</div>
      <TextArea
        name="TextArea1"
        placeholder="Placeholder"
        onChange={handleChange}
      />
      <TextArea
        name="TextArea2"
        placeholder="Disabled"
        disabled
        onChange={handleChange}
      />

      <Divide />

      <div>Search</div>
      <Search onChange={handleChange} onClear={() => handleClear('Search')} />
      <Search
        disabled
        onChange={handleChange}
        onClear={() => handleClear('Search')}
      />

      <Divide />

      <div>Password</div>
      <Password
        name="Password1"
        label="Password"
        placeholder="Placeholder"
        onChange={handleChange}
      />
      <Password
        name="Password2"
        placeholder="Placeholder"
        onChange={handleChange}
      />
      <Password
        name="Password3"
        label="Password"
        placeholder="Disabled"
        disabled
        onChange={handleChange}
      />
      <Password
        name="Password4"
        placeholder="Disabled"
        disabled
        onChange={handleChange}
      />

      <Divide />

      <div>Telephone</div>
      <Telephone
        name="Telephone1"
        label="Telephone"
        placeholder="Placeholder"
        onChange={handleChange}
      />
      <Telephone name="Telephone2" label="Telephone" onChange={handleChange} />
      <Telephone
        name="Telephone3"
        placeholder="Placeholder"
        onChange={handleChange}
      />
      <Telephone name="Telephone4" onChange={handleChange} />
      <Telephone
        name="Telephone5"
        placeholder="Disabled"
        disabled
        onChange={handleChange}
      />
      <Telephone name="Telephone6" variant="stacked" onChange={handleChange} />
      <Telephone
        name="Telephone7"
        variant="stacked"
        placeholder="Placeholder"
        onChange={handleChange}
      />
      <Telephone
        name="Telephone8"
        variant="stacked"
        placeholder="Disabled"
        disabled
        onChange={handleChange}
      />
    </form>
  );
};
