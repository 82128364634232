/* configs */
import { msalInstance } from '../../../..';

/* components */
import { Link } from '../../Link/Link';
import { Button } from '../../Button/Button';

/* icons */
import { ReactComponent as Chat } from '../../../../icons/chat.svg';
import { ReactComponent as Book } from '../../../../icons/book.svg';
import { ReactComponent as Settings } from '../../../../icons/settings.svg';
import { ReactComponent as Logout } from '../../../../icons/logout.svg';

interface Props {
  handleDisplay: (display: boolean) => void;
}

export const UserMenu = (props: Props) => {
  return (
    <div
      onClick={() => props.handleDisplay(false)}
      className="absolute w-full flex flex-col gap-2xs p-xs rounded-standard border 
                  border-color-stroke-secondary bg-color-menu-surface-base
                  bottom-12 z-10"
    >
      <Link
        to="/chat-support"
        icon={<Chat className="fill-inherit" />}
        label="Chat Support"
        notifications={0}
        className={'hover:bg-color-menu-surface-secondary'}
        activeClassName={'bg-color-menu-surface-primary'}
      />
      <Link
        to="/help-guides"
        icon={<Book className="fill-inherit" />}
        label="Help Guides"
        notifications={0}
        className={'hover:bg-color-menu-surface-secondary'}
        activeClassName={'bg-color-menu-surface-primary'}
      />
      <Link
        to="/preferences"
        icon={<Settings className="fill-inherit" />}
        label="Preferences"
        notifications={0}
        className={'hover:bg-color-menu-surface-secondary'}
        activeClassName={'bg-color-menu-surface-primary'}
      />
      <Button
        onClick={() => msalInstance.logoutRedirect()}
        icon={<Logout className="fill-inherit" />}
        label="Logout"
        notifications={0}
        className={'hover:bg-color-menu-surface-secondary'}
        activeClassName={'bg-color-menu-surface-primary'}
      />
    </div>
  );
};
