export const Dashboard = () => {
  return (
    <>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <br />
      <h5>Lead Regular</h5>
      <h6>Lead Medium</h6>
      <br />
      <p>Body Regular</p>
      <p className="font-medium">Body Medium</p>
      <br />
      <p className="caption">Caption Regular</p>
      <p className="caption-medium">Caption Medium</p>
      <br />
      <p className="subheading">Subheading</p>
      <p className="subheading-bold">Subheading Bold</p>
      <p className="subheading-condensed font-bold">Subheading Condensed</p>
      <br />
      <div className="flex flex-col gap-sm">
        <div className="p-4 h-24 w-24 shadow-sm rounded-lg">Shadow Small</div>
        <div className="p-4 h-24 w-24 shadow-md rounded-lg">Shadow Medium</div>
        <div className="p-4 h-24 w-24 shadow-lg rounded-lg">Shadow Large</div>
      </div>
      <br />
      <p>Scrim</p>
      <div className="relative h-min w-80">
        <div className="absolute scrim ml-40 w-1/2 h-full"></div>
        <div className="p-4 h-full w-full shadow-sm rounded-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
      </div>
    </>
  );
};
