import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* types */
import { RootState } from '../store';
import { OrganisationI } from '../../types/organisation';

const initialState: OrganisationI = {
  id: -1,
  name: 'Unknown',
  image: 'purple.svg'
};

/* slice */
export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setOrganisation: (_state, action: PayloadAction<OrganisationI>) => {
      return action.payload;
    }
  }
});

/* actions */
export const { setOrganisation } = organisationSlice.actions;

/* state */
export const organisation = (state: RootState) => state.organisation;

/* reducer */
export default organisationSlice.reducer;
