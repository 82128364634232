import { ChangeEvent } from 'react';

interface Props {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = (props: Props) => {
  const { name, placeholder, disabled, required } = props;

  return (
    <textarea
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      onChange={props.onChange}
    />
  );
};
