import { useEffect, useMemo } from 'react';

/* configs */
import api from '../../configs/api';

/* redux */
import { useAppDispatch } from '../../redux/hooks';
import { setUser } from '../../redux/slices/user';
import { setLoading } from '../../redux/slices/loading';
import { setOrganisation } from '../../redux/slices/organisation';

export const Initialisation = () => {
  const dispatch = useAppDispatch();

  const promises: Promise<void>[] = useMemo(
    () => [
      /* user */
      api
        .get(`/profile`)
        .then((response) => {
          dispatch(setUser(response.data));
        })
        .catch((error) => console.log(error)),

      /* organisation */
      api
        .get(`/organisations`)
        .then((response) => {
          dispatch(setOrganisation(response.data[0]));
        })
        .catch((error) => console.log(error))
    ],
    /* dependancies */
    [dispatch]
  );

  /* stop loading after all initialisation is complete */
  useEffect(() => {
    Promise.all(promises)
      .then(() => dispatch(setLoading(false)))
      .catch((error) => console.log(error));
  }, [promises, dispatch]);

  return <></>;
};
