import { ChangeEvent, useEffect, useState } from 'react';

/* components */
import { Input } from '../Input';
import { CountryCodes } from './CountryCodes/CountryCodes';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  variant?: 'inline' | 'stacked';
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Telephone = (props: Props) => {
  const { name, label, placeholder, disabled, required, variant } = props;

  const [code, setCode] = useState<string>();
  const [event, setEvent] = useState<ChangeEvent<HTMLInputElement>>();

  /* update the value when the input or select value changes */
  useEffect(() => {
    if (event) {
      const value = event.target.value;
      event.target.value = code && value.length ? code + value : value;
      props.onChange(event);
    }
    // eslint-disable-next-line
  }, [event, code]);

  return (
    <Input
      name={name}
      select={
        <CountryCodes variant={variant} onChange={(code) => setCode(code)} />
      }
      type="tel"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      variant={variant || 'inline'}
      onChange={(e) => setEvent(e)}
    />
  );
};
